import { ref } from "vue";
import c3api from "../c3api";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

export default function useBins() {

    const binLoading = ref(0);

    const bins = ref([]);
    const bin = ref(null);
    const locationBreakdowns = ref(null);
    const lps = ref(null);
    const toast = useToast()
    const router = useRouter()

    const fetchBins = async ( params = null ) => {
        try {
            binLoading.value++;
            const response = await c3api.get('/bins', { params: params });
            return (bins.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            binLoading.value--;
        }
    }

    const fetchBin = async (id) => {
        try {
            binLoading.value++;
            const response = await c3api.get(`/bins/${id}`);
            return (bin.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            binLoading.value--;
        }
    };

    const deleteBin = async (id) => {
        try {
            binLoading.value++;
            await c3api.delete(`/bins/${id}`);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            binLoading.value--;
        }
    };

    const fetchLocationBreakdowns = async (id) => {
        try {
            binLoading.value++;
            const response = await c3api.get(`/bins/${id}/location_breakdown`);
            return (locationBreakdowns.value = response.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            binLoading.value--;
        }
    };

    const fetchLps = async (id, urlParams) => {
        try {
            binLoading.value++;
            const response = await c3api.get(`/bins/${id}/lps`, { params: urlParams });
            return (lps.value = response.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            binLoading.value--;
        }
    };

    const addBin = async (data) => {
        try {
            binLoading.value++;
            const response = await c3api.post("/bins", data);
            toast.success("Bin Added Successfully.");
            router.push('/bins');
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            binLoading.value--;
        }
    }

    const updateBin = async (id, data) => {
        try {
            binLoading.value++;
            const response = await c3api.patch(`/bins/${id}`, data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            binLoading.value--;
        }
    }

    return {
        binLoading,
        bin,
        fetchBin,
        deleteBin,
        locationBreakdowns,
        fetchLocationBreakdowns,
        lps,
        fetchLps,
        bins,
        fetchBins,
        addBin,
        updateBin
    }
}